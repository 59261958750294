.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70vw;
  min-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
}
