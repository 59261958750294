$delete-red: rgb(242, 65, 65);

.container {
  position: relative;
  cursor: pointer;
}

.img-container {
  border: 2px solid #1876d1;
  overflow: hidden;
  & svg {
    transition: all 2s ease;
    transform: scale(1);
  }
  &:hover svg {
    transform: scale(1.3);
  }
}

.delete-button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  top: 2px;
  right: 2px;
  transform-origin: center;
  z-index: 100;
  transition: all 0.5s ease;
  &:hover {
    background: $delete-red;
    &::after,
    &::before {
      background: #fff;
    }
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background: $delete-red;
    top: 50%;
    left: 50%;
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
