$primary-color: #1876d1;
$secondary-color: #fff;

.container {
  position: relative;
  display: grid;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-camera {
  background: $primary-color;
  color: $secondary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  min-height: 20rem;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background: $secondary-color;
    color: $primary-color;
    border: 2px solid $primary-color;
  }
}
