.page-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-container {
  position: relative;
}

.component-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
}

.down-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.btns-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
}

.alert-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
