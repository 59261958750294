.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  max-height: 90vh;
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
